import { tv } from '@29cm/configs/tailwind-variants';
import { Link } from '@29cm/contexts-common-views';
import { MySideMenuItem } from '@29cm/contexts-navigations-constants';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

interface Props {
  item: MySideMenuItem;
  postfix?: string;
}

const menuStyle = tv({
  variants: {
    active: {
      true: 'text-xl-bold text-primary',
      false: 'text-l text-secondary',
    },
  },
});

export const MySideMenu = ({ item, postfix }: Props) => {
  const pathname = usePathname();
  const active = useMemo(() => new RegExp(item.urlRegex).test(pathname), [item.urlRegex, pathname]);

  return (
    <Link href={item.url} className={menuStyle({ active })}>
      {item.name} {postfix}
    </Link>
  );
};
