import { tv, VariantProps } from '@29cm/configs/tailwind-variants';
import { forwardRef, useEffect, useRef } from 'react';
import { Icon } from '../Icon';
import { CheckboxProps } from './Checkbox.types';

const checkboxVariant = tv({
  slots: {
    label: 'flex flex-row items-start gap-8 hover:text-primary-hover',
    box: 'shrink-0 grow-0 rounded-2 border border-line bg-silent text-on-color transition-colors hover:bg-silent-hover focus-visible:outline focus-visible:outline-1 focus-visible:outline-interactive  peer-checked:border-on-black peer-checked:border-on-black-hover peer-checked:bg-on-black peer-checked:bg-on-black-hover peer-indeterminate:border-on-black peer-indeterminate:border-on-black-hover peer-indeterminate:bg-on-black peer-indeterminate:bg-on-black-hover',
    context: 'cursor-pointer select-none overflow-hidden text-ellipsis whitespace-nowrap',
  },
  variants: {
    size: {
      small: {
        box: 'h-18 w-18',
        context: 'text-m',
      },
      medium: {
        box: 'h-24 w-24',
        context: 'text-xxl',
      },
    },
    contextPosition: {
      left: {
        label: 'flex-row-reverse',
      },
      right: {},
    },
    stretch: {
      true: {
        label: 'flex-1 justify-between',
      },
    },
    disabled: {
      true: {
        label: 'text-disabled',
        box: 'border-line-disabled bg-silent text-disabled hover:bg-silent-hover peer-checked:border-line-disabled peer-checked:bg-silent peer-checked:hover:bg-silent-hover peer-indeterminate:border-line-disabled peer-indeterminate:bg-silent peer-indeterminate:hover:bg-silent-hover',
        context: 'text-disabled',
      },
    },
  },
});

export const Checkbox = forwardRef<HTMLLabelElement, CheckboxProps & VariantProps<typeof checkboxVariant>>(
  (
    {
      label,
      size = 'medium',
      contextPosition = 'right',
      stretch,
      checked,
      onChange,
      onInput,
      value,
      name,
      indeterminate,
      disabled,
      readOnly,
      className,
      ...props
    },
    ref,
  ) => {
    const {
      label: labelSlot,
      box: boxSlot,
      context: contextSlot,
    } = checkboxVariant({
      size,
      contextPosition,
      stretch,
      disabled: disabled,
    });

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.indeterminate = !!indeterminate;
      }
    }, [indeterminate]);

    return (
      <label className={labelSlot({ className })} ref={ref} {...props}>
        <input
          ref={inputRef}
          type="checkbox"
          className="peer absolute -z-content-1 h-0 w-0"
          checked={indeterminate ? false : checked}
          onChange={onChange}
          onInput={onInput}
          value={value}
          name={name}
          disabled={disabled}
          readOnly={readOnly}
        />
        <div className={boxSlot()}>
          {indeterminate ? <Icon icon="MinusRegularIcon" className="block" /> : null}
          {checked && !indeterminate ? <Icon icon="CheckRegularIcon" className="block" /> : null}
        </div>
        {label && typeof label === 'string' ? <span className={contextSlot()}>{label}</span> : null}
      </label>
    );
  },
);
