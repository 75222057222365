'use client';

import { tv } from '@29cm/configs/tailwind-variants';
import { PropsWithChildren } from 'react';
import { useSearchDialogContext } from '../SearchDialogContext';

const overlay = tv({
  base: 'fixed left-0 top-0 z-mask flex h-screen w-screen flex-col items-end bg-default bg-opacity-[0.98]',
  variants: {
    isOpen: {
      false: 'hidden',
    },
  },
});

// FIXME: overlay용 portal을 만들어 따로 관리하기
export const SearchOverlay: React.FC<PropsWithChildren> = ({ children }) => {
  const { isOpen } = useSearchDialogContext();
  return <div className={overlay({ isOpen })}>{children}</div>;
};
