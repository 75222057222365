import { VariantProps, tv } from '@29cm/configs/tailwind-variants';
import { forwardRef, useMemo } from 'react';
import { Icon } from '../Icon';
import { RatingGroupProps } from './RatingGroup.types';

const ratingGroupVariant = tv({
  slots: {
    container: 'relative inline-flex',
    bg: 'flex flex-row items-center [&>*]:text-gray-100',
    sprite: 'flex flex-row items-center [&>*]:text-primary',
    mask: 'absolute left-0 top-0 overflow-clip transition-[width]',
    star: 'h-24 w-24',
  },
  variants: {
    size: {
      small: {
        bg: 'gap-0 [&>*]:h-14 [&>*]:w-14',
        sprite: 'gap-0 [&>*]:h-14 [&>*]:w-14',
        star: 'h-14 w-14',
      },
      medium: {
        bg: 'gap-6 [&>*]:h-24 [&>*]:w-24',
        sprite: 'gap-6  [&>*]:h-24 [&>*]:w-24',
        star: 'h-24 w-24',
      },
    },
    accent: {
      true: {
        sprite: '[&>*]:text-accent',
      },
    },
    readOnly: {
      true: {
        bg: '[&>*]:text-gray-400',
      },
    },
  },
});

/**
 * NOTE(Jaeho)
 * `medium` 크기에서는 별 사이에 6px의 gap이 들어갑니다.
 * `rating`이 그대로 적용 될 경우 mask layer에 오차가 생기기 때문에
 * 값을 보정해 줍니다.
 * icon = 24 = 16.667%, gap = 6 = 4.166%, total width = 144px(100%)
 * maskWidth = (rating * icon) + (Math.floor(rating) * gap)
 */
const numStars = 5;
const gap = 4.166; // %, 6/144
const starSize = 16.667; // %, 24/144

/**
 * Adjust if not in 0 <= rating <= 5
 * @param rating
 * @returns
 */
const adjustRating = (rating: number) => {
  if (typeof rating !== 'number') {
    return 0;
  } else if (rating < 0) {
    return 0;
  } else if (rating > numStars) {
    return numStars;
  } else {
    return rating;
  }
};

/**
 * x.1 ~ x.9 => x.5, x.0 => x
 * @param rating
 * @returns
 */
const adjustDecimalPointToHalf = (rating: number) => {
  return rating % 1 !== 0 ? Math.floor(rating) + 0.5 : rating;
};

/**
 * On the size of `medium`, there is a gap with 6px.
 * Need to add extra width on the right side of each star.
 * @param hasGap
 * @param rating
 * @returns
 */
const retrieveWidthPct = (hasGap: boolean, rating: number) => {
  return hasGap ? Math.round((rating * starSize + Math.floor(rating) * gap) * 100) / 100 : (rating * 100) / numStars;
};

export const RatingGroup = forwardRef<HTMLButtonElement, RatingGroupProps & VariantProps<typeof ratingGroupVariant>>(
  ({ rating = 0, size, className, accent, step = 'half', readOnly, onClick, ...props }, ref) => {
    const {
      container: containerSlot,
      bg: bgSlot,
      sprite: spriteSlot,
      mask: maskSlot,
      star: starSlot,
    } = ratingGroupVariant({
      size,
      accent,
      readOnly,
    });

    const maskWidth = useMemo(() => {
      const adjustedRating = adjustRating(rating);

      // `half` 일때 소수점은 0.5 단위로 표기합니다.
      const displayRating = step === 'half' ? adjustDecimalPointToHalf(adjustedRating) : adjustedRating;

      return Math.min(retrieveWidthPct(size === 'medium', displayRating), 100);
    }, [rating, step, size]);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const btn = e.currentTarget as HTMLButtonElement;
      // 별점은 1점 이상으로 선택 가능
      const ratingValue = Math.max(1, Math.ceil((e.nativeEvent.layerX / btn.clientWidth) * numStars));

      // NOTE: 1점일 때 다시 1점을 선택하면 초기화 합니다.
      onClick?.(e, ratingValue === 1 && ratingValue === rating ? 0 : ratingValue);
    };

    return (
      <button className={containerSlot({ className })} ref={ref} {...props} onClick={handleClick} disabled={readOnly}>
        <div className={bgSlot()}>
          {Array(numStars)
            .fill(0)
            .map((_, index) => (
              <Icon
                key={`star-bg-${index.toString()}`}
                icon="StarBoldFillIcon"
                className={starSlot()}
                svgClass="w-[inherit] h-[inherit]"
              />
            ))}
        </div>

        <div className={maskSlot()} style={{ width: `${maskWidth}%` }}>
          <div className={spriteSlot()}>
            {Array(numStars)
              .fill(0)
              .map((_, index) => (
                <Icon
                  key={`star-${index.toString()}`}
                  icon="StarBoldFillIcon"
                  className={starSlot()}
                  svgClass="w-[inherit] h-[inherit]"
                />
              ))}
          </div>
        </div>
      </button>
    );
  },
);
