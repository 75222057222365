import { cn } from '@29cm/configs/tailwind-variants';
import { CategoryItem } from '@29cm/contexts-categories-services';
import { shopAppPath } from '@29cm/contexts-common-constants';
import { Link } from '@29cm/contexts-common-views';
import { urlJoin } from '@29cm/utils-url';

const getSortParamForCategoryItem = (categoryItem: CategoryItem): string =>
  categoryItem.name === 'NEW' ? 'defaultSort=NEW' : '';

interface CategoryListProps {
  className?: string;
  categoryItem: CategoryItem;
}

export const CategoryList: React.FC<CategoryListProps> = ({ className, categoryItem }) => {
  return (
    <section className={cn('flex flex-col gap-8', className)}>
      <Link
        href={urlJoin(
          shopAppPath,
          `/category/main?category=${categoryItem.code}&categoryLargeCode=${categoryItem.code}`,
        )}
      >
        {/* TODO: TextButton 사용하기 */}
        <h2 className="text-xxl-bold text-primary">{categoryItem.name}</h2>
      </Link>
      <ul className="flex min-h-0 flex-1 flex-col flex-wrap content-start gap-y-8">
        {categoryItem.subCategories?.map((subCategoryItem) => (
          <li key={subCategoryItem.code} className="w-[176px] text-s hover:text-s-bold">
            <Link
              href={urlJoin(
                shopAppPath,
                `category/list?categoryLargeCode=${categoryItem.code}&categoryMediumCode=${subCategoryItem.code}&${getSortParamForCategoryItem(subCategoryItem)}`,
              )}
            >
              {/* TODO: TextButton 사용하기 */}
              {subCategoryItem.name}
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
};
