import { VariantProps, tv } from '@29cm/configs/tailwind-variants';
import { forwardRef } from 'react';
import { Icon } from '../Icon';
import { Spinner } from '../Spinner';
import { IconButtonProps } from './IconButton.types';

export const buttonVariant = tv({
  slots: {
    button:
      'relative flex flex-row items-center justify-center gap-2 rounded-2 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-interactive',
    icon: 'shrink-0 grow-0', // NOTE: icon will inherit color and bg
    spinner: 'absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center rounded-2',
  },
  variants: {
    priority: {
      primary: {
        button: 'bg-primary text-on-color hover:bg-primary-hover focus:bg-primary active:bg-primary-pressed',
        spinner: 'bg-primary',
      },
      secondary: {
        button:
          'border border-line bg-silent text-primary hover:bg-silent-hover hover:text-primary-hover active:bg-silent-pressed active:text-primary-pressed',
        spinner: 'bg-silent',
      },
      tertiary: {
        button: 'bg-tertiary text-primary hover:text-primary-hover active:text-primary-pressed',
        spinner: 'bg-tertiary',
      },
    },
    size: {
      xSmall: {
        button: 'h-28 w-28 rounded-2 p-6',
        icon: 'h-16 w-16',
        spinner: 'rounded-2',
      },
      small: {
        button: 'h-36 w-36 rounded-2 p-8',
        icon: 'h-20 w-20',
        spinner: 'rounded-2',
      },
      medium: {
        button: 'h-44 w-44 rounded-4 p-12',
        icon: 'h-22 w-22',
        spinner: 'rounded-4',
      },
      large: {
        button: 'h-52 w-52 rounded-4 p-14',
        icon: 'h-24 w-24',
        spinner: 'rounded-4',
      },
    },
    isWhite: {
      true: {
        button:
          'bg-on-white text-primary visited:bg-on-white hover:bg-on-white hover:text-primary-hover focus:bg-on-white active:bg-on-white active:text-primary-pressed',
        spinner: 'bg-on-white',
      },
    },
    transparent: {
      true: {
        button:
          'bg-transparent text-primary hover:bg-transparent hover:text-primary-hover focus:bg-transparent focus:text-primary-hover active:bg-transparent active:text-primary-pressed',
        spinner: 'bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent',
      },
    },
    disabled: {
      true: {
        button:
          'bg-disabled text-disabled hover:bg-disabled hover:text-disabled focus:bg-disabled focus:text-disabled active:bg-disabled active:text-disabled',
      },
    },
  },
  compoundVariants: [
    {
      isWhite: true,
      transparent: true,
      disabled: false,
      class: {
        button: 'text-on-color hover:text-on-color focus:text-on-color active:text-on-color',
      },
    },
    {
      transparent: true,
      disabled: true,
      class: {
        button:
          'bg-transparent text-disabled hover:bg-transparent hover:text-disabled focus:bg-transparent focus:text-disabled active:bg-transparent active:text-disabled',
      },
    },
    {
      isWhite: true,
      transparent: false,
      disabled: true,
      class: {
        button: 'bg-on-white',
      },
    },
  ],
  defaultVariants: {
    disabled: false,
    transparent: false,
  },
});

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps & VariantProps<typeof buttonVariant>>(
  (
    { priority = 'primary', size = 'medium', disabled, pending, icon, transparent, isWhite, className, ...otherProps },
    ref,
  ) => {
    const priorityTransparent = transparent ? 'primary' : priority; // NOTE: transparent 는 primary 에서만 가능합니다.
    const {
      button: buttonSlot,
      icon: iconSlot,
      spinner: spinnerSlot,
    } = buttonVariant({
      size,
      priority: priorityTransparent,
      disabled,
      transparent,
      isWhite,
    });

    return (
      <button
        ref={ref}
        {...otherProps}
        className={buttonSlot({ className })}
        disabled={disabled || pending}
        aria-readonly={disabled || pending}
      >
        {icon && (!(transparent && pending) || disabled) ? <Icon icon={icon} className={iconSlot()} /> : null}
        {!disabled && pending ? (
          <div className={spinnerSlot()}>
            <Spinner size={size} onColor={priorityTransparent === 'primary'} />
          </div>
        ) : null}
      </button>
    );
  },
);
