import { cn, tv, VariantProps } from '@29cm/configs/tailwind-variants';
import { loadIcon } from '@29cm/contexts-common-icon/loader';
import { lazy, memo, Suspense } from 'react';
import { IconProps } from './Icon.types';

/**
 * Lazy loading으로 아이콘을 그려줍니다.
 * icon 이외에 다른 props를 추가하지 마세요. props가 변경 되면 아이콘이 깜빡입니다.
 */
const IconModule = memo(
  ({ icon, className = 'block' }: IconProps) => {
    const MatchedIcon = lazy(loadIcon(icon));

    return (
      <Suspense fallback={<div className="w-fulll h-full" />}>
        <MatchedIcon
          className={cn('h-full w-full align-[unset]', className)}
          style={{ color: 'inherit', backgroundColor: 'inherit' }}
        />
      </Suspense>
    );
  },
  (prev, next) => prev.icon === next.icon,
);

const iconVariant = tv({
  base: 'inline-block',
  variants: {
    size: {
      xSmall: 'h-12 w-12',
      small: 'h-14 w-14',
      medium: 'h-16 w-16',
      large: 'h-18 w-18',
      xLarge: 'h-20 w-20',
    },
  },
});

export const Icon = ({ icon, size, className, svgClass }: IconProps & VariantProps<typeof iconVariant>) => {
  const mergedClassName = iconVariant({ size, className });
  return (
    <div className={mergedClassName}>
      <IconModule icon={icon} className={svgClass} />
    </div>
  );
};
