import { tv } from '@29cm/configs/tailwind-variants';

export const baseFocusVisible = tv({
  base: 'focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-1 focus-visible:outline-interactive',
});

export const baseTag = tv({
  slots: {
    base: [baseFocusVisible(), `inline-flex cursor-pointer items-center justify-center rounded-full px-14 py-6`],
    label: 'text-m-medium hover:text-primary-hover  active:text-primary-pressed',
  },
  variants: {
    active: {
      true: {
        base: 'pressed:bg-primary-pressed bg-primary text-on-color hover:bg-primary-hover',
        label: 'text-on-color hover:text-on-white-hover active:text-on-white-pressed',
      },
      false: {
        base: 'bg-tertiary text-secondary',
        label: 'text-secondary hover:text-secondary-hover active:text-secondary-pressed',
      },
    },
    disabled: {
      true: {
        base: 'cursor-not-allowed bg-disabled [&_*]:cursor-not-allowed',
        label: 'text-disabled hover:text-disabled active:text-disabled',
        icon: 'text-disabled',
      },
    },
  },
});
