import { tv } from '@29cm/configs/tailwind-variants';
import { ChevronDownBoldFillIcon } from '@29cm/contexts-common-icon';
import { forwardRef } from 'react';
import { baseTag } from '../Tag.styles';
import type { TagProps } from '../Tag.types';
import { useTag } from '../useTag';

const filterTag = tv({
  extend: baseTag,
  slots: {
    label: 'flex items-center justify-between gap-6',
  },
});

export const FilterTag = forwardRef<HTMLButtonElement, TagProps>(
  ({ className, active = false, disabled, onClick, children, bgClass, ...rest }, ref) => {
    const { base, label } = filterTag({ active, disabled });
    const { handleClick, className: computedClass } = useTag({ disabled, onClick, bgClass, className });

    return (
      <button {...rest} ref={ref} className={base({ className: computedClass })} onClick={handleClick}>
        <span className={label()}>
          {children}
          <ChevronDownBoldFillIcon size={14} />
        </span>
      </button>
    );
  },
);
