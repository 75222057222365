import { wrapApiResponse } from '@29cm/contexts-http/utils';
import * as v from 'valibot';

export const ReviewCountResponse = wrapApiResponse(
  v.object({
    writableCount: v.number(),
    writableOrderCount: v.number(),
    writableGiftCount: v.number(),
    wroteCount: v.number(),
    possibleEarnMileage: v.number(),
    possibleEarnMileageOrder: v.number(),
    possibleEarnMileageGift: v.number(),
  }),
);

export type ReviewCountResponse = v.InferInput<typeof ReviewCountResponse>;
