'use client';

import { isHangulProclamationDay } from '../../../utils';

export const Logo: React.FC = () => {
  if (isHangulProclamationDay()) {
    return <img className="h-16" src="https://asset.29cm.co.kr/cms/202410/11ef8555c038d8d08fb2b94a23cf4d5d.svg" />;
  } else {
    return (
      <img
        className="h-16"
        src="https://img.29cm.co.kr/next-contents/2023/06/08/3f8131682d124d16b336774ba51c4a3e_20230608162823.png"
      />
    );
  }
};
