import { legacyAppPath, orderAppPath } from '@29cm/contexts-common-constants';
import { urlJoin } from '@29cm/utils-url';

export interface MySideMenuItem {
  name: string;
  url: string;
  eventName: string;
  urlRegex: RegExp;
}

// TODO:(이조은) 메뉴 툴팁, pc&모바일 노출
export const mySideMenuItems: { title: string; items: MySideMenuItem[] }[] = [
  {
    title: '나의 쇼핑정보',
    items: [
      {
        name: '주문배송조회',
        url: urlJoin(orderAppPath, '/my-order/list'),
        eventName: 'MAIN_MY_ORDER_LIST',
        urlRegex: /\/my-order\/list/i,
      },
      {
        name: '숙박예약조회',
        url: urlJoin(orderAppPath, '/my-order/booking'),
        eventName: 'MAIN_MY_ORDER_LIST',
        urlRegex: /\/my-order\/booking/i,
      },
      {
        name: '취소/교환/반품 내역',
        url: urlJoin(legacyAppPath, '/mypage/my-order/cancel-list'),
        eventName: 'MAIN_MY_ORDER_CANCEL_LIST',
        urlRegex: /\/mypage\/my-order\/cancel-list/i,
      },
      {
        name: '상품 리뷰',
        url: urlJoin(orderAppPath, '/my-order/review'),
        eventName: 'MAIN_MY_ORDER_REVIEW',
        urlRegex: /\/my-order\/review/i,
      },
      {
        name: '증빙서류 발급',
        url: urlJoin(orderAppPath, '/my-order/receipt'),
        eventName: 'MAIN_MY_ORDER_RECEIPT',
        urlRegex: /\/my-order\/receipt/i,
      },
    ],
  },
  {
    title: '나의 계정설정',
    items: [
      {
        name: '회원정보수정',
        url: urlJoin(legacyAppPath, '/mypage/edit/reconfirm'),
        eventName: 'MAIN_MY_EDIT_INFO',
        urlRegex: /\/mypage\/edit\/info/i,
      },
      {
        name: '회원등급',
        url: urlJoin(orderAppPath, '/my-order/grade'),
        eventName: 'MAIN_MY_GRADE',
        urlRegex: /\/grade/i,
      },
      {
        name: '쿠폰',
        url: urlJoin(legacyAppPath, '/mypage/coupon'),
        eventName: 'MAIN_MY_COUPON',
        urlRegex: /\/mypage\/coupon/i,
      },
      {
        name: '마일리지',
        url: urlJoin(legacyAppPath, '/mypage/mileage'),
        eventName: 'MAIN_MY_MILEAGE',
        urlRegex: /\/mypage\/mileage/i,
      },
    ],
  },
  {
    title: '고객센터',
    items: [
      {
        name: '1:1 문의내역',
        url: urlJoin(orderAppPath, '/my-order/cscenter/qna/qna-mantoman-list'),
        eventName: 'MAIN_MY_QNA_MANTOMAN',
        urlRegex: /\/cscenter\/qna\/qna-mantoman-list/i,
      },
      {
        name: '상품 Q&A내역',
        url: urlJoin(orderAppPath, '/my-order/cscenter/qna/qna-product-list'),
        eventName: 'MAIN_MY_QNA_PRODUCT',
        urlRegex: /\/cscenter\/qna\/qna-product-list/i,
      },
      {
        name: '공지사항',
        url: urlJoin(orderAppPath, '/my-order/cscenter/notice/notice-list'),
        eventName: 'MAIN_MY_NOTICE',
        urlRegex: /\/cscenter\/notice/i,
      },
      {
        name: 'FAQ',
        url: urlJoin(legacyAppPath, '/mypage/cscenter/faq/faq-list'),
        eventName: 'MAIN_MY_FAQ',
        urlRegex: /\/mypage\/cscenter\/faq/i,
      },
      {
        name: '고객의 소리',
        url: urlJoin(legacyAppPath, '/mypage/cscenter/voc'),
        eventName: 'VOC',
        urlRegex: /\/mypage\/cscenter\/voc/i,
      },
    ],
  },
];
