const iconMap = {
  ['ArrowLeftBoldFillIcon']: () => import('./components/IcArrowLeftBoldFill'),
  ['ArrowLeftBoldIcon']: () => import('./components/IcArrowLeftBold'),
  ['ArrowLeftLightIcon']: () => import('./components/IcArrowLeftLight'),
  ['ArrowLeftRegularIcon']: () => import('./components/IcArrowLeftRegular'),
  ['ArrowRightBoldFillIcon']: () => import('./components/IcArrowRightBoldFill'),
  ['ArrowRightBoldIcon']: () => import('./components/IcArrowRightBold'),
  ['ArrowRightLightIcon']: () => import('./components/IcArrowRightLight'),
  ['ArrowRightRegularIcon']: () => import('./components/IcArrowRightRegular'),
  ['BagBoldFillIcon']: () => import('./components/IcBagBoldFill'),
  ['BagBoldIcon']: () => import('./components/IcBagBold'),
  ['BagLightIcon']: () => import('./components/IcBagLight'),
  ['BagRegularIcon']: () => import('./components/IcBagRegular'),
  ['BarsBoldFillIcon']: () => import('./components/IcBarsBoldFill'),
  ['BarsBoldIcon']: () => import('./components/IcBarsBold'),
  ['BarsLightIcon']: () => import('./components/IcBarsLight'),
  ['BarsRegularIcon']: () => import('./components/IcBarsRegular'),
  ['BellBoldFillIcon']: () => import('./components/IcBellBoldFill'),
  ['BellBoldIcon']: () => import('./components/IcBellBold'),
  ['BellLightIcon']: () => import('./components/IcBellLight'),
  ['BellRegularIcon']: () => import('./components/IcBellRegular'),
  ['BelloffBoldFillIcon']: () => import('./components/IcBelloffBoldFill'),
  ['BelloffBoldIcon']: () => import('./components/IcBelloffBold'),
  ['BelloffLightIcon']: () => import('./components/IcBelloffLight'),
  ['BelloffRegularIcon']: () => import('./components/IcBelloffRegular'),
  ['BoxBoldFillIcon']: () => import('./components/IcBoxBoldFill'),
  ['BoxBoldIcon']: () => import('./components/IcBoxBold'),
  ['BoxLightIcon']: () => import('./components/IcBoxLight'),
  ['BoxRegularIcon']: () => import('./components/IcBoxRegular'),
  ['CameraBoldFillIcon']: () => import('./components/IcCameraBoldFill'),
  ['CameraBoldIcon']: () => import('./components/IcCameraBold'),
  ['CameraLightIcon']: () => import('./components/IcCameraLight'),
  ['CameraRegularIcon']: () => import('./components/IcCameraRegular'),
  ['CautionBoldFillIcon']: () => import('./components/IcCautionBoldFill'),
  ['CautionBoldIcon']: () => import('./components/IcCautionBold'),
  ['CautionLightIcon']: () => import('./components/IcCautionLight'),
  ['CautionRegularIcon']: () => import('./components/IcCautionRegular'),
  ['CheckBoldFillIcon']: () => import('./components/IcCheckBoldFill'),
  ['CheckBoldIcon']: () => import('./components/IcCheckBold'),
  ['CheckCalendarBoldFillIcon']: () => import('./components/IcCheckCalendarBoldFill'),
  ['CheckCalendarBoldIcon']: () => import('./components/IcCheckCalendarBold'),
  ['CheckCalendarLightIcon']: () => import('./components/IcCheckCalendarLight'),
  ['CheckCalendarRegularIcon']: () => import('./components/IcCheckCalendarRegular'),
  ['CheckCircleBoldFillIcon']: () => import('./components/IcCheckCircleBoldFill'),
  ['CheckCircleBoldIcon']: () => import('./components/IcCheckCircleBold'),
  ['CheckCircleLightIcon']: () => import('./components/IcCheckCircleLight'),
  ['CheckCircleRegularIcon']: () => import('./components/IcCheckCircleRegular'),
  ['CheckLightIcon']: () => import('./components/IcCheckLight'),
  ['CheckRegularIcon']: () => import('./components/IcCheckRegular'),
  ['ChevronDownBoldFillIcon']: () => import('./components/IcChevronDownBoldFill'),
  ['ChevronDownBoldIcon']: () => import('./components/IcChevronDownBold'),
  ['ChevronDownLightIcon']: () => import('./components/IcChevronDownLight'),
  ['ChevronDownRegularIcon']: () => import('./components/IcChevronDownRegular'),
  ['ChevronLeftBoldFillIcon']: () => import('./components/IcChevronLeftBoldFill'),
  ['ChevronLeftBoldIcon']: () => import('./components/IcChevronLeftBold'),
  ['ChevronLeftLightIcon']: () => import('./components/IcChevronLeftLight'),
  ['ChevronLeftRegularIcon']: () => import('./components/IcChevronLeftRegular'),
  ['ChevronRightBoldFillIcon']: () => import('./components/IcChevronRightBoldFill'),
  ['ChevronRightBoldIcon']: () => import('./components/IcChevronRightBold'),
  ['ChevronRightLightIcon']: () => import('./components/IcChevronRightLight'),
  ['ChevronRightRegularIcon']: () => import('./components/IcChevronRightRegular'),
  ['ChevronUpBoldFillIcon']: () => import('./components/IcChevronUpBoldFill'),
  ['ChevronUpBoldIcon']: () => import('./components/IcChevronUpBold'),
  ['ChevronUpLightIcon']: () => import('./components/IcChevronUpLight'),
  ['ChevronUpRegularIcon']: () => import('./components/IcChevronUpRegular'),
  ['ClockBoldFillIcon']: () => import('./components/IcClockBoldFill'),
  ['ClockBoldIcon']: () => import('./components/IcClockBold'),
  ['ClockLightIcon']: () => import('./components/IcClockLight'),
  ['ClockRegularIcon']: () => import('./components/IcClockRegular'),
  ['CloseBoldFillIcon']: () => import('./components/IcCloseBoldFill'),
  ['CloseBoldIcon']: () => import('./components/IcCloseBold'),
  ['CloseLightIcon']: () => import('./components/IcCloseLight'),
  ['CloseRegularIcon']: () => import('./components/IcCloseRegular'),
  ['CommentBoldFillIcon']: () => import('./components/IcCommentBoldFill'),
  ['CommentBoldIcon']: () => import('./components/IcCommentBold'),
  ['CommentLightIcon']: () => import('./components/IcCommentLight'),
  ['CommentRegularIcon']: () => import('./components/IcCommentRegular'),
  ['CouponBoldFillIcon']: () => import('./components/IcCouponBoldFill'),
  ['CouponBoldIcon']: () => import('./components/IcCouponBold'),
  ['CouponLightIcon']: () => import('./components/IcCouponLight'),
  ['CouponRegularIcon']: () => import('./components/IcCouponRegular'),
  ['DeliveryBoldFillIcon']: () => import('./components/IcDeliveryBoldFill'),
  ['DeliveryBoldIcon']: () => import('./components/IcDeliveryBold'),
  ['DeliveryLightIcon']: () => import('./components/IcDeliveryLight'),
  ['DeliveryRegularIcon']: () => import('./components/IcDeliveryRegular'),
  ['DisclosureOffBoldFillIcon']: () => import('./components/IcDisclosureOffBoldFill'),
  ['DisclosureOffBoldIcon']: () => import('./components/IcDisclosureOffBold'),
  ['DisclosureOffLightIcon']: () => import('./components/IcDisclosureOffLight'),
  ['DisclosureOffRegularIcon']: () => import('./components/IcDisclosureOffRegular'),
  ['DisclosureOnBoldFillIcon']: () => import('./components/IcDisclosureOnBoldFill'),
  ['DisclosureOnBoldIcon']: () => import('./components/IcDisclosureOnBold'),
  ['DisclosureOnLightIcon']: () => import('./components/IcDisclosureOnLight'),
  ['DisclosureOnRegularIcon']: () => import('./components/IcDisclosureOnRegular'),
  ['DocumentBoldFillIcon']: () => import('./components/IcDocumentBoldFill'),
  ['DocumentBoldIcon']: () => import('./components/IcDocumentBold'),
  ['DocumentLightIcon']: () => import('./components/IcDocumentLight'),
  ['DocumentRegularIcon']: () => import('./components/IcDocumentRegular'),
  ['DownloadBoldFillIcon']: () => import('./components/IcDownloadBoldFill'),
  ['DownloadBoldIcon']: () => import('./components/IcDownloadBold'),
  ['DownloadLightIcon']: () => import('./components/IcDownloadLight'),
  ['DownloadRegularIcon']: () => import('./components/IcDownloadRegular'),
  ['EyeOffBoldFillIcon']: () => import('./components/IcEyeOffBoldFill'),
  ['EyeOffBoldIcon']: () => import('./components/IcEyeOffBold'),
  ['EyeOffLightIcon']: () => import('./components/IcEyeOffLight'),
  ['EyeOffRegularIcon']: () => import('./components/IcEyeOffRegular'),
  ['EyeOnBoldFillIcon']: () => import('./components/IcEyeOnBoldFill'),
  ['EyeOnBoldIcon']: () => import('./components/IcEyeOnBold'),
  ['EyeOnLightIcon']: () => import('./components/IcEyeOnLight'),
  ['EyeOnRegularIcon']: () => import('./components/IcEyeOnRegular'),
  ['FilterBoldFillIcon']: () => import('./components/IcFilterBoldFill'),
  ['FilterBoldIcon']: () => import('./components/IcFilterBold'),
  ['FilterDotBoldFillIcon']: () => import('./components/IcFilterDotBoldFill'),
  ['FilterDotBoldIcon']: () => import('./components/IcFilterDotBold'),
  ['FilterDotLightIcon']: () => import('./components/IcFilterDotLight'),
  ['FilterDotRegularIcon']: () => import('./components/IcFilterDotRegular'),
  ['FilterLightIcon']: () => import('./components/IcFilterLight'),
  ['FilterRegularIcon']: () => import('./components/IcFilterRegular'),
  ['FoldChevronLeftBoldFillIcon']: () => import('./components/IcFoldChevronLeftBoldFill'),
  ['FoldChevronLeftBoldIcon']: () => import('./components/IcFoldChevronLeftBold'),
  ['FoldChevronLeftLightIcon']: () => import('./components/IcFoldChevronLeftLight'),
  ['FoldChevronLeftRegularIcon']: () => import('./components/IcFoldChevronLeftRegular'),
  ['FoldChevronRightBoldFillIcon']: () => import('./components/IcFoldChevronRightBoldFill'),
  ['FoldChevronRightBoldIcon']: () => import('./components/IcFoldChevronRightBold'),
  ['FoldChevronRightLightIcon']: () => import('./components/IcFoldChevronRightLight'),
  ['FoldChevronRightRegularIcon']: () => import('./components/IcFoldChevronRightRegular'),
  ['GiftBoldFillIcon']: () => import('./components/IcGiftBoldFill'),
  ['GiftBoldIcon']: () => import('./components/IcGiftBold'),
  ['GiftLightIcon']: () => import('./components/IcGiftLight'),
  ['GiftRegularIcon']: () => import('./components/IcGiftRegular'),
  ['HangerBoldFillIcon']: () => import('./components/IcHangerBoldFill'),
  ['HangerBoldIcon']: () => import('./components/IcHangerBold'),
  ['HangerLightIcon']: () => import('./components/IcHangerLight'),
  ['HangerRegularIcon']: () => import('./components/IcHangerRegular'),
  ['HeartBoldFillIcon']: () => import('./components/IcHeartBoldFill'),
  ['HeartBoldIcon']: () => import('./components/IcHeartBold'),
  ['HeartLightIcon']: () => import('./components/IcHeartLight'),
  ['HeartRegularIcon']: () => import('./components/IcHeartRegular'),
  ['HomeBoldFillIcon']: () => import('./components/IcHomeBoldFill'),
  ['HomeBoldIcon']: () => import('./components/IcHomeBold'),
  ['HomeLightIcon']: () => import('./components/IcHomeLight'),
  ['HomeRegularIcon']: () => import('./components/IcHomeRegular'),
  ['HouseBoldFillIcon']: () => import('./components/IcHouseBoldFill'),
  ['HouseBoldIcon']: () => import('./components/IcHouseBold'),
  ['HouseLightIcon']: () => import('./components/IcHouseLight'),
  ['HouseRegularIcon']: () => import('./components/IcHouseRegular'),
  ['InfoCircleBoldFillIcon']: () => import('./components/IcInfoCircleBoldFill'),
  ['InfoCircleBoldIcon']: () => import('./components/IcInfoCircleBold'),
  ['InfoCircleLightIcon']: () => import('./components/IcInfoCircleLight'),
  ['InfoCircleRegularIcon']: () => import('./components/IcInfoCircleRegular'),
  ['LawBoldFillIcon']: () => import('./components/IcLawBoldFill'),
  ['LawBoldIcon']: () => import('./components/IcLawBold'),
  ['LawLightIcon']: () => import('./components/IcLawLight'),
  ['LawRegularIcon']: () => import('./components/IcLawRegular'),
  ['LockBoldFillIcon']: () => import('./components/IcLockBoldFill'),
  ['LockBoldIcon']: () => import('./components/IcLockBold'),
  ['LockLightIcon']: () => import('./components/IcLockLight'),
  ['LockRegularIcon']: () => import('./components/IcLockRegular'),
  ['LockoffBoldFillIcon']: () => import('./components/IcLockoffBoldFill'),
  ['LockoffBoldIcon']: () => import('./components/IcLockoffBold'),
  ['LockoffLightIcon']: () => import('./components/IcLockoffLight'),
  ['LockoffRegularIcon']: () => import('./components/IcLockoffRegular'),
  ['MilIcon']: () => import('./components/IcMil'),
  ['MinusBoldFillIcon']: () => import('./components/IcMinusBoldFill'),
  ['MinusBoldIcon']: () => import('./components/IcMinusBold'),
  ['MinusLightIcon']: () => import('./components/IcMinusLight'),
  ['MinusRegularIcon']: () => import('./components/IcMinusRegular'),
  ['MyBoldFillIcon']: () => import('./components/IcMyBoldFill'),
  ['MyBoldIcon']: () => import('./components/IcMyBold'),
  ['MyLightIcon']: () => import('./components/IcMyLight'),
  ['MyRegularIcon']: () => import('./components/IcMyRegular'),
  ['PencilBoldFillIcon']: () => import('./components/IcPencilBoldFill'),
  ['PencilBoldIcon']: () => import('./components/IcPencilBold'),
  ['PencilLightIcon']: () => import('./components/IcPencilLight'),
  ['PencilRegularIcon']: () => import('./components/IcPencilRegular'),
  ['PictureBoldFillIcon']: () => import('./components/IcPictureBoldFill'),
  ['PictureBoldIcon']: () => import('./components/IcPictureBold'),
  ['PictureLightIcon']: () => import('./components/IcPictureLight'),
  ['PictureRegularIcon']: () => import('./components/IcPictureRegular'),
  ['PlusBoldFillIcon']: () => import('./components/IcPlusBoldFill'),
  ['PlusBoldIcon']: () => import('./components/IcPlusBold'),
  ['PlusLightIcon']: () => import('./components/IcPlusLight'),
  ['PlusRegularIcon']: () => import('./components/IcPlusRegular'),
  ['QuestionBoldFillIcon']: () => import('./components/IcQuestionBoldFill'),
  ['QuestionBoldIcon']: () => import('./components/IcQuestionBold'),
  ['QuestionLightIcon']: () => import('./components/IcQuestionLight'),
  ['QuestionRegularIcon']: () => import('./components/IcQuestionRegular'),
  ['RedoBoldFillIcon']: () => import('./components/IcRedoBoldFill'),
  ['RedoBoldIcon']: () => import('./components/IcRedoBold'),
  ['RedoLightIcon']: () => import('./components/IcRedoLight'),
  ['RedoRegularIcon']: () => import('./components/IcRedoRegular'),
  ['RemoveBoldFillIcon']: () => import('./components/IcRemoveBoldFill'),
  ['RemoveBoldIcon']: () => import('./components/IcRemoveBold'),
  ['RemoveLightIcon']: () => import('./components/IcRemoveLight'),
  ['RemoveRegularIcon']: () => import('./components/IcRemoveRegular'),
  ['ScrapBoldFillIcon']: () => import('./components/IcScrapBoldFill'),
  ['ScrapBoldIcon']: () => import('./components/IcScrapBold'),
  ['ScrapLightIcon']: () => import('./components/IcScrapLight'),
  ['ScrapRegularIcon']: () => import('./components/IcScrapRegular'),
  ['SearchBoldFillIcon']: () => import('./components/IcSearchBoldFill'),
  ['SearchBoldIcon']: () => import('./components/IcSearchBold'),
  ['SearchLightIcon']: () => import('./components/IcSearchLight'),
  ['SearchListBoldFillIcon']: () => import('./components/IcSearchListBoldFill'),
  ['SearchListBoldIcon']: () => import('./components/IcSearchListBold'),
  ['SearchListLightIcon']: () => import('./components/IcSearchListLight'),
  ['SearchListRegularIcon']: () => import('./components/IcSearchListRegular'),
  ['SearchRegularIcon']: () => import('./components/IcSearchRegular'),
  ['SettingBoldFillIcon']: () => import('./components/IcSettingBoldFill'),
  ['SettingBoldIcon']: () => import('./components/IcSettingBold'),
  ['SettingLightIcon']: () => import('./components/IcSettingLight'),
  ['SettingRegularIcon']: () => import('./components/IcSettingRegular'),
  ['ShareBoldFillIcon']: () => import('./components/IcShareBoldFill'),
  ['ShareBoldIcon']: () => import('./components/IcShareBold'),
  ['ShareLightIcon']: () => import('./components/IcShareLight'),
  ['ShareRegularIcon']: () => import('./components/IcShareRegular'),
  ['SpaceBoldFillIcon']: () => import('./components/IcSpaceBoldFill'),
  ['SpaceBoldIcon']: () => import('./components/IcSpaceBold'),
  ['SpaceLightIcon']: () => import('./components/IcSpaceLight'),
  ['SpaceRegularIcon']: () => import('./components/IcSpaceRegular'),
  ['StarBoldFillIcon']: () => import('./components/IcStarBoldFill'),
  ['StarBoldIcon']: () => import('./components/IcStarBold'),
  ['StarHalfBoldFillIcon']: () => import('./components/IcStarHalfBoldFill'),
  ['StarHalfBoldIcon']: () => import('./components/IcStarHalfBold'),
  ['StarHalfLightIcon']: () => import('./components/IcStarHalfLight'),
  ['StarHalfRegularIcon']: () => import('./components/IcStarHalfRegular'),
  ['StarLightIcon']: () => import('./components/IcStarLight'),
  ['StarRegularIcon']: () => import('./components/IcStarRegular'),
  ['TrashBoldFillIcon']: () => import('./components/IcTrashBoldFill'),
  ['TrashBoldIcon']: () => import('./components/IcTrashBold'),
  ['TrashLightIcon']: () => import('./components/IcTrashLight'),
  ['TrashRegularIcon']: () => import('./components/IcTrashRegular'),
  ['WonBoldFillIcon']: () => import('./components/IcWonBoldFill'),
  ['WonBoldIcon']: () => import('./components/IcWonBold'),
  ['WonLightIcon']: () => import('./components/IcWonLight'),
  ['WonRegularIcon']: () => import('./components/IcWonRegular'),
  ['WonIcon']: () => import('./components/IcWon'),
};

export type IconName = keyof typeof iconMap;
export const iconNames = Object.keys(iconMap);
export const loadIcon = (icon: IconName) => iconMap[icon];
