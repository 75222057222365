import { tv } from '@29cm/configs/tailwind-variants';
import { forwardRef, useCallback } from 'react';
import ProductCardInfo from '../ProductCard/ProductCardInfo';
import { CompactCardPreview } from './CompactCardPreview';
import { CompactProductCardProps } from './CompactProductCard.types';

export const compactProductCard = tv({
  base: 'mb-16 space-y-8 rounded-4',
});

export const CompactProductCard = forwardRef<HTMLDivElement, CompactProductCardProps>(
  ({ imgUrl, onHeart, onHeartClick, onClick, extraBadge, className, renderImage, rank, ...props }, ref) => {
    const handleClick = useCallback(() => onClick?.(), [onClick]);

    return (
      <div className={compactProductCard({ className })} onClick={handleClick} ref={ref} role="button">
        <CompactCardPreview
          rank={rank}
          imgUrl={imgUrl}
          onHeart={onHeart}
          onHeartClick={onHeartClick}
          extraBadge={extraBadge}
          renderImage={renderImage}
        />
        <ProductCardInfo {...props} size="compact" productNameLineClamp="truncate" showMeta={false} />
      </div>
    );
  },
);
