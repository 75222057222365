import { useCachedFetch } from '@29cm/contexts-common-hooks';
import { getResultData } from '@29cm/contexts-http/utils';
import { User } from '@29cm/contexts-users/interfaces';
import { fetchUserSummary } from '@29cm/contexts-users/services';

interface Props {
  user: User;
}

export const MyHeartCount = ({ user }: Props) => {
  const { result: userSummaryResult } = useCachedFetch(fetchUserSummary, { id: user.no });
  const userSummary = getResultData(userSummaryResult);

  return <span className="mb-40 block text-xl-bold">{`좋아요 ${userSummary?.userHeartTotal ?? 0}`}</span>;
};
