import { forwardRef } from 'react';
import { IconButton } from '../IconButton';
import { ToggleIconButtonProps } from './ToggleIconButton.types';

export const ToggleIconButton = forwardRef<HTMLButtonElement, ToggleIconButtonProps>(
  ({ icon, selectedIcon, selected = false, size = 'medium', ...props }, ref) => {
    return (
      <IconButton
        {...props}
        ref={ref}
        icon={selected && selectedIcon ? selectedIcon : icon}
        size={size}
        priority={selected ? 'tertiary' : 'primary'}
      />
    );
  },
);
