'use client';

import { tv } from '@29cm/configs/tailwind-variants';
import { EmoIcon } from '@29cm/contexts-common-ruler';
import { Link } from '@29cm/contexts-common-views';
import { GlobalTabBarItem } from '@29cm/contexts-navigations-constants';
import { usePathname } from 'next/navigation';
import { ReactNode, useMemo } from 'react';
import { ShopFillIcon, ShopIcon } from './icons';

const iconByName: Record<GlobalTabBarItem['name'], { default: ReactNode; active: ReactNode }> = {
  'HOME': {
    default: <EmoIcon type="home" size={24} />,
    active: <EmoIcon type="home" size={24} fill />,
  },
  'SHOP': {
    default: <ShopIcon />,
    active: <ShopFillIcon />,
  },
  'SEARCH': {
    default: <EmoIcon type="search" size={24} />,
    active: <EmoIcon type="search" size={24} fill />,
  },
  '29MAGAZINE': {
    default: <EmoIcon type="box" size={24} />,
    active: <EmoIcon type="box" size={24} fill />,
  },
  'MY': {
    default: <EmoIcon type="my" size={24} />,
    active: <EmoIcon type="my" size={24} fill />,
  },
};

const menuStyle = tv({
  base: 'flex h-56 flex-col items-center justify-center gap-[5px] text-[9px]',
  variants: {
    active: {
      true: 'text-primary',
      false: 'text-secondary',
    },
  },
});

interface GlobalTabBarMenuProps {
  item: GlobalTabBarItem;
}

export const GlobalTabBarMenu: React.FC<GlobalTabBarMenuProps> = ({ item }) => {
  const pathname = usePathname();
  const active = useMemo(() => new RegExp(item.urlRegex).test(pathname), [item.urlRegex, pathname]);
  return (
    <Link href={item.url} className={menuStyle({ active })}>
      {active ? iconByName[item.name].active : iconByName[item.name].default}
      {item.name}
    </Link>
  );
};
