import { tv } from '@29cm/configs/tailwind-variants';
import { forwardRef } from 'react';
import { IconButton } from '../IconButton';
import { LikeButtonProps } from './LikeButton.types';

const likeButton = tv({
  variants: {
    selected: {
      true: '[&_div]:text-accent ',
    },
  },
});

export const LikeButton = forwardRef<HTMLButtonElement, LikeButtonProps>(
  ({ selected = false, size = 'medium', className, ...props }, ref) => {
    return (
      <IconButton
        {...props}
        className={likeButton({ selected, className })}
        ref={ref}
        icon={selected ? 'HeartBoldFillIcon' : 'HeartRegularIcon'}
        size={size}
        priority={selected ? 'tertiary' : 'primary'}
      />
    );
  },
);
