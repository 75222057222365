import { tv } from '@29cm/configs/tailwind-variants';
import { forwardRef } from 'react';
import { baseTag } from '../Tag.styles';
import type { TagProps } from '../Tag.types';
import { useTag } from '../useTag';

const toggleTag = tv({
  extend: baseTag,
});

export const ToggleTag = forwardRef<HTMLButtonElement, TagProps>(
  ({ className, active = false, disabled, onClick, children, bgClass, ...rest }, ref) => {
    const { base, label } = toggleTag({ active, disabled });
    const { handleClick, className: computedClass } = useTag({ disabled, onClick, bgClass, className });

    return (
      <button
        {...rest}
        ref={ref}
        className={base({ className: computedClass })}
        onClick={handleClick}
        disabled={disabled}
      >
        <span className={label()}>{children}</span>
      </button>
    );
  },
);
