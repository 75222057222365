import { tv } from '@29cm/configs/tailwind-variants';
import { RemoveBoldFillIcon } from '@29cm/contexts-common-icon';
import { forwardRef } from 'react';
import { baseFocusVisible, baseTag } from '../Tag.styles';
import { useTag } from '../useTag';
import type { RemovableTagProps } from './RemovableTag.types';

const filterTag = tv({
  extend: baseTag,
  slots: {
    label: 'flex items-center justify-between gap-6',
    icon: [
      'text-tertiary hover:text-tertiary-hover',
      baseFocusVisible(),
      'focus-visible:p-1 focus-visible:rounded-full',
    ],
  },
});

export const RemovableTag = forwardRef<HTMLButtonElement, RemovableTagProps>(
  ({ className, disabled, onClick, children, ...rest }, ref) => {
    const { base, label, icon } = filterTag({ disabled });
    const { handleClick } = useTag({ disabled, onClick });

    return (
      <button {...rest} ref={ref} className={base({ className })} onClick={handleClick} disabled={disabled}>
        <span className={label()}>
          <span>{children}</span>
          <button className={icon()} disabled={disabled}>
            <RemoveBoldFillIcon size={14} />
          </button>
        </span>
      </button>
    );
  },
);
