import { apihubApiPath, apihubCacheApiPath, appEnv } from '@29cm/contexts-common-constants';
import { createFetcher } from '@29cm/contexts-http/services';
import { urlJoin } from '@29cm/utils-url';
import * as v from 'valibot';
import { FetcherKey } from '../../../common/constants/src';
import { UserSummary } from '../interfaces/UserSummary';
import { UserSummaryResponse } from '../response-schemas/UserSummaryResponse';

const apiPath = appEnv === 'development' ? apihubApiPath : apihubCacheApiPath;

export const fetchUserSummary = createFetcher<{ id: string }, UserSummaryResponse, UserSummary | undefined>({
  key: FetcherKey.FetchUserSummary,
  method: 'GET',
  url: ({ id }) => urlJoin(apiPath, `/order/user-summary/${id}/`),
  requestOption: {
    credentials: 'include',
  },
  validator: (data) => v.safeParse(UserSummaryResponse, data).issues?.map((issue) => issue.message),
  transformer: (data) => {
    if (!data) {
      return;
    }

    return {
      userNo: data.user_no,
      userId: data.user_id,
      userName: data.user_name,
      userGender: data.user_gender,
      userGrade: data.user_grade,
      userGradeName: data.user_grade_name,
      userEmail: data.user_email,
      orderCountTotal: data.order_count_total,
      userMileageTotal: data.user_mileage_total,
      userCouponTotal: data.user_coupon_total,
      userDepositTotal: data.user_deposit_total,
      userHeartTotal: data.user_heart_total,
      userHeartCount: data.user_heart_count.map(({ heart_type, total }) => {
        return {
          heartType: heart_type,
          total,
        };
      }),
      userReviewWrote: data.user_review_wrote,
      userReviewWritable: data.user_review_writable,
      userReviewSampleWritable: data.user_review_sample_writable,
      orderCountInProgress: data.order_count_in_progress,
      totalPossibleEarnReviewMileage: data.total_possible_earn_review_mileage,
      joinedAt: data.joined_at,
      firstOrderedAt: data.first_ordered_at,
      birthYear: data.birth_year,
    };
  },
});
