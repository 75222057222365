import { tv, VariantProps } from '@29cm/configs/tailwind-variants';
import { forwardRef } from 'react';
import { Icon } from '../Icon';
import { Spinner } from '../Spinner';
import { ButtonIconProps, ButtonLabelProps, ButtonProps, ButtonSpinnerProps } from './Button.types';

const disabledClassName =
  'bg-disabled text-disabled hover:bg-disabled hover:text-disabled focus:bg-disabled focus:text-disabled active:bg-disabled active:text-disabled';

const buttonVariant = tv({
  base: 'relative flex flex-row items-center justify-center gap-2 rounded-2 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-interactive',
  variants: {
    size: {
      xSmall: 'h-28 min-w-[60px] rounded-2 px-10 py-6 text-xs font-normal',
      small: 'h-36 min-w-[66px] rounded-2 px-12 py-10 text-s font-medium',
      medium: 'h-44 min-w-[84px] rounded-4 px-18 py-12 text-l font-bold ',
      large: 'h-52 min-w-[88px] rounded-4 px-20 py-16 text-l font-bold',
    },
    priority: {
      primary: 'bg-primary text-on-color hover:bg-primary-hover focus:bg-primary active:bg-primary-pressed',
      secondary:
        'border border-line bg-silent text-primary hover:bg-silent-hover hover:text-primary-hover active:bg-silent-pressed active:text-primary-pressed',
      tertiary: 'bg-tertiary text-primary hover:text-primary-hover active:text-primary-pressed',
      negative: 'bg-negative text-on-color hover:bg-negative-hover focus:bg-negative active:bg-negative-pressed',
    },
    disabled: {
      true: disabledClassName,
    },
    stretch: {
      true: 'flex-1',
    },
  },
  defaultVariants: {
    priority: 'primary',
    size: 'medium',
    disabled: false,
    stretch: false,
  },
});

const iconVariant = tv({
  base: 'shrink-0 grow-0 bg-transparent',
  variants: {
    size: {
      xSmall: 'h-12 w-12',
      small: 'h-14 w-14',
      medium: 'h-16 w-16',
      large: 'h-18 w-18',
    },
    priority: {
      primary: 'text-on-color',
      secondary: 'text-primary  hover:text-primary-hover active:text-primary-pressed',
      tertiary: ' text-primary hover:text-primary-hover active:text-primary-pressed',
      negative: ' text-on-color',
    },
    disabled: {
      true: disabledClassName,
    },
  },
});

const spinnerVariant = tv({
  base: 'absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center rounded-2',
  variants: {
    size: {
      xSmall: 'rounded-2',
      small: 'rounded-2',
      medium: 'rounded-4',
      large: 'rounded-4',
    },
    priority: {
      primary: 'bg-primary',
      secondary: 'bg-silent',
      tertiary: 'bg-tertiary',
      negative: 'bg-negative',
    },
  },
});

const labelVariant = tv({
  base: 'overflow-hidden text-ellipsis whitespace-nowrap bg-transparent',
  defaultVariants: {
    size: 'medium',
    priority: 'primary',
  },
  variants: {
    size: {
      xSmall: 'ml-0',
      small: 'ml-0',
      medium: 'ml-2',
      large: 'ml-2',
    },
    priority: {
      primary: 'text-on-color',
      secondary: 'text-primary  hover:text-primary-hover active:text-primary-pressed',
      tertiary: ' text-primary hover:text-primary-hover active:text-primary-pressed',
      negative: ' text-on-color',
    },
    disabled: {
      true: disabledClassName,
    },
  },
});

const ButtonLabel = ({ label, size, priority, disabled }: ButtonLabelProps & VariantProps<typeof labelVariant>) => {
  const mergedClassName = labelVariant({ size, priority, disabled });

  return label && label !== '' ? <span className={mergedClassName}>{label}</span> : null;
};

const ButtonIcon = ({ icon, size, priority, disabled }: ButtonIconProps & VariantProps<typeof iconVariant>) => {
  const mergedClassName = iconVariant({ size, priority, disabled });

  return icon ? <Icon icon={icon} className={mergedClassName} /> : null;
};

const ButtonSpinner = ({ priority, size }: ButtonSpinnerProps & VariantProps<typeof spinnerVariant>) => {
  const mergedClassName = spinnerVariant({ priority, size });

  return (
    <div className={mergedClassName}>
      <Spinner size={size} onColor={priority === 'primary' || priority === 'negative'} />
    </div>
  );
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps & VariantProps<typeof buttonVariant>>(
  (
    {
      className,
      priority = 'primary',
      size = 'medium',
      disabled,
      pending,
      label,
      prefixIcon,
      postfixIcon,
      stretch,
      ...otherProps
    },
    ref,
  ) => {
    const mergedClassName = buttonVariant({ size, priority, disabled, stretch, className });

    return (
      <button
        ref={ref}
        {...otherProps}
        className={mergedClassName}
        disabled={disabled || pending}
        aria-readonly={disabled || pending}
      >
        {prefixIcon ? <ButtonIcon icon={prefixIcon} size={size} priority={priority} disabled={disabled} /> : null}
        <ButtonLabel label={label} size={size} priority={priority} disabled={disabled} />
        {postfixIcon ? <ButtonIcon icon={postfixIcon} size={size} priority={priority} disabled={disabled} /> : null}

        {!disabled && pending ? <ButtonSpinner priority={priority} size={size} /> : null}
      </button>
    );
  },
);
