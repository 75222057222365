import { useCachedFetch } from '@29cm/contexts-common-hooks';
import { getResultData } from '@29cm/contexts-http/utils';
import { User } from '@29cm/contexts-users/interfaces';
import { fetchUserSummary } from '@29cm/contexts-users/services';

interface Props {
  user: User;
}

export const MyName = ({ user }: Props) => {
  const { result: userSummaryResult } = useCachedFetch(fetchUserSummary, { id: user.no });
  const userSummary = getResultData(userSummaryResult);

  return (
    <span className="mb-[25px] block text-[40px] font-bold leading-[48px]">{userSummary?.userName ?? '회원님'}</span>
  );
};
