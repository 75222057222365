import * as v from 'valibot';
import { GenderType } from '../enums/GenderType';
import { HeartType } from '../enums/HeartType';
import { UserGrade } from '../interfaces/UserGrade';

export const UserSummaryResponse = v.object({
  user_no: v.number(),
  user_id: v.string(),
  user_name: v.string(),
  user_gender: v.enum(GenderType),
  user_grade: UserGrade,
  user_grade_name: v.string(),
  user_email: v.string(),
  order_count_total: v.number(),
  user_mileage_total: v.number(),
  user_coupon_total: v.number(),
  user_deposit_total: v.number(),
  user_heart_total: v.number(),
  user_heart_count: v.array(
    v.object({
      heart_type: v.enum(HeartType),
      total: v.number(),
    }),
  ),
  user_review_wrote: v.number(),
  user_review_writable: v.number(),
  user_review_sample_writable: v.number(),
  order_count_in_progress: v.number(),
  total_possible_earn_review_mileage: v.number(),
  joined_at: v.string(),
  first_ordered_at: v.nullable(v.string()),
  birth_year: v.string(),
});

export type UserSummaryResponse = v.InferInput<typeof UserSummaryResponse>;
