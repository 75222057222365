import { HeartBoldFillIcon, HeartBoldIcon } from '@29cm/contexts-common-icon';
import { useCallback } from 'react';
import { Badge } from '../Badge';
import type { PreviewProps } from '../ProductCard/CardPreview';

export interface CompactCardPreview extends PreviewProps {
  rank?: number;
}

export const CompactCardPreview = ({
  onHeart = false,
  onHeartClick,
  imgUrl,
  rank,
  extraBadge,
  renderImage = ({ className, src }) => {
    // TODO: 이미지 최적화 적용하기
    return <img className={className} src={src} loading="lazy" />;
  },
}: CompactCardPreview) => {
  const handleHeartClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault(); // 기본 동작 방지 (e.g. 부모 요소 링크 이동)
      e.stopPropagation();
      onHeartClick?.();
    },
    [onHeartClick],
  );

  return (
    <div className="preview relative aspect-square">
      {rank && <Rank rank={rank} />}
      {renderImage({
        className: 'object-fit w-full h-full rounded-2',
        src: imgUrl,
      })}
      <div className="absolute inset-x-0 bottom-0 ">{extraBadge && <ExtraBadge label={extraBadge} />}</div>
      {/* TODO:(신다혜) - LikeButton이 제작되면 교체가 필요합니다. */}
      <button className="heart-icon-shadow absolute right-10 top-10" onClick={handleHeartClick}>
        {onHeart ? (
          <HeartBoldFillIcon className="text-accent" size={24} />
        ) : (
          <HeartBoldIcon className="text-on-white" size={24} />
        )}
      </button>
    </div>
  );
};

const Rank = ({ rank }: { rank: number }) => {
  return (
    <div className="absolute inline-flex h-24 w-24 items-center justify-center rounded-4 bg-on-black">
      <div className="text-s-medium text-on-white">{rank}</div>
    </div>
  );
};

const ExtraBadge = ({ label }: { label: string }) => {
  return (
    <Badge size="small" priority="primary" className="absolute bottom-6 left-6">
      {label}
    </Badge>
  );
};
