import { tv, VariantProps } from '@29cm/configs/tailwind-variants';
import { forwardRef } from 'react';
import { Icon } from '../Icon';
import { TextButtonProps } from './TextButton.type';

const textButtonVariant = tv({
  slots: {
    button:
      'inline-block align-middle focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-1 focus-visible:outline-interactive',
    icon: 'inline-block shrink-0 grow-0 bg-transparent align-middle first:mr-2 last:ml-2',
    label: 'align-middle',
  },
  variants: {
    priority: {
      primary: {
        button:
          'text-primary [&:not(:disabled)]:hover:text-primary-hover [&:not(:disabled)]:active:text-primary-pressed',
      },
      secondary: {
        button:
          'text-secondary [&:not(:disabled)]:hover:text-secondary-hover [&:not(:disabled)]:active:text-secondary-pressed',
      },
      tertiary: {
        button:
          'text-tertiary [&:not(:disabled)]:hover:text-tertiary-hover [&:not(:disabled)]:active:text-tertiary-pressed',
      },
    },
    size: {
      xSmall: {
        button: 'text-s',
        label: 'text-s',
        icon: 'w-12 text-s',
      },
      small: {
        button: 'text-l',
        label: 'text-l',
        icon: 'w-14 text-l',
      },
      medium: {
        button: 'text-xxl',
        label: 'text-xxl',
        icon: 'w-16 text-xxl',
      },
      large: {
        button: 'text-title-xs',
        label: 'text-title-xs',
        icon: 'w-18 text-title-xs first:mr-4 last:ml-2',
      },
    },
    // NOTE: compoundVariants 에서 `size` 마다 다르게 설정을 합니다.
    isBold: {
      true: {},
    },
    isUnderline: {
      true: {
        label: 'border-b',
      },
    },
    isWhite: {
      true: {
        button:
          'text-on-white [&:not(:disabled)]:hover:text-on-white-hover [&:not(:disabled)]:active:text-on-white-pressed',
      },
    },
    disabled: {
      true: {
        button: 'text-disabled',
      },
    },
  },
  compoundVariants: [
    {
      size: 'xSmall',
      isBold: true,
      class: {
        button: 'text-s-bold',
        label: 'text-s-bold',
      },
    },
    {
      size: 'small',
      isBold: true,
      class: {
        button: 'text-l-bold',
        label: 'text-l-bold',
      },
    },
    {
      size: 'medium',
      isBold: true,
      class: {
        button: 'text-xxl-bold',
        label: 'text-xxl-bold',
      },
    },
    {
      size: 'large',
      isBold: true,
      class: {
        button: 'text-title-xs-bold',
        label: 'text-title-xs-bold',
      },
    },
  ],
});

export const TextButton = forwardRef<HTMLAnchorElement, TextButtonProps & VariantProps<typeof textButtonVariant>>(
  (
    {
      label,
      priority = 'primary',
      size = 'medium',
      isBold,
      isUnderline,
      isWhite,
      disabled,
      prefixIcon,
      postfixIcon,
      onClick,
      href = '',
      className,
      ...props
    },
    ref,
  ) => {
    const {
      button: buttonSlot,
      icon: iconSlot,
      label: labelSlot,
    } = textButtonVariant({
      priority,
      size,
      isBold,
      isUnderline,
      isWhite,
      disabled,
    });

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (!href) {
        e.preventDefault();
      }
      onClick?.(e);
    };

    return (
      <a ref={ref} className={buttonSlot({ className })} href={href} {...props} onClick={handleClick} role="button">
        {prefixIcon ? <Icon icon={prefixIcon} size={size} className={iconSlot()} /> : null}
        <span className={labelSlot()}>{label}</span>
        {postfixIcon ? <Icon icon={postfixIcon} size={size} className={iconSlot()} /> : null}
      </a>
    );
  },
);
