'use client';

import { Link } from '@29cm/contexts-common-views';
import { GlobalNavigationPrimaryMenuItem } from '@29cm/contexts-navigations-constants';
import { isHangulProclamationDay } from '../../../utils';

interface PrimaryMenuItemProps {
  item: GlobalNavigationPrimaryMenuItem;
}

export const PrimaryMenuItem: React.FC<PrimaryMenuItemProps> = ({ item }) => {
  return (
    <li className="relative whitespace-pre text-[20px] font-bold text-primary before:absolute before:bottom-0 before:left-0 before:w-full before:bg-primary before:content-[''] hover:before:h-6 min-[720px]:text-[30px] min-[1200px]:text-[40px]">
      <Link href={item.url}>{isHangulProclamationDay() ? item.korName : item.name}</Link>
    </li>
  );
};
