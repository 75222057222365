'use client';

import { useFetch } from '@29cm/contexts-common-hooks';
import { getResultData } from '@29cm/contexts-http/utils';
import { mySideMenuItems } from '@29cm/contexts-navigations-constants';
import { fetchReviewCount } from '@29cm/contexts-review/services';
import { useUserContext } from '@29cm/contexts-users/features';
import { MyHeartCount } from './MyHeartCount';
import { MyName } from './MyName';
import { MySideMenu } from './MySideMenu';

// TODO:(이조은) 이벤트 수집, 반응형(/my-order), API 응답 실패&로딩에 따른 대응
export const MySideMenuModule = () => {
  const { user } = useUserContext();

  const { result: reviewCountResult } = useFetch(fetchReviewCount, {});
  const reviewCount = getResultData(reviewCountResult);

  return (
    <nav>
      {user ? <MyName user={user} /> : null}
      {user ? <MyHeartCount user={user} /> : null}
      <ul className="flex flex-col gap-40">
        {mySideMenuItems.map((menu) => {
          return (
            <li key={menu.title}>
              <ul className="flex flex-col gap-12">
                <h3 className="text-title-xs-bold">{menu.title}</h3>
                {menu.items.map((item) => {
                  const postfix = item.name === '상품 리뷰' ? `${reviewCount?.writableCount || ''}` : undefined;

                  return (
                    <li key={item.name}>
                      <MySideMenu item={item} postfix={postfix} />
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
