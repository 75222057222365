import { FetcherKey, reviewApiPath } from '@29cm/contexts-common-constants';
import { createFetcher } from '@29cm/contexts-http/services';
import { urlJoin } from '@29cm/utils-url';
import * as v from 'valibot';
import { ReviewCount } from '../interfaces/ReviewCount';
import { ReviewCountResponse } from '../response-schemas/ReviewCountResponse';

export const fetchReviewCount = createFetcher<Record<string, never>, ReviewCountResponse, ReviewCount | undefined>({
  key: FetcherKey.FetchReviewCount,
  method: 'GET',
  url: () => urlJoin(reviewApiPath, `/api/v4/reviews/my-page-count`),
  requestOption: {
    credentials: 'include',
  },
  validator: (data) => v.safeParse(ReviewCountResponse, data).issues?.map((issue) => issue.message),
  transformer: ({ data }) => {
    if (!data) {
      return;
    }

    return {
      writableCount: data.writableCount,
      writableOrderCount: data.writableOrderCount,
      writableGiftCount: data.writableGiftCount,
      wroteCount: data.wroteCount,
      possibleEarnMileage: data.possibleEarnMileage,
      possibleEarnMileageOrder: data.possibleEarnMileageOrder,
      possibleEarnMileageGift: data.possibleEarnMileageGift,
    };
  },
});
