import { cn } from '@29cm/configs/tailwind-variants';
import { useCallback, useMemo } from 'react';
import type { TagProps } from './Tag.types';

type UseTagProps = Pick<TagProps, 'disabled' | 'onClick' | 'className' | 'bgClass'>;

export const useTag = (props: UseTagProps) => {
  const { disabled, onClick, className, bgClass } = props;

  const handleClick = useCallback(() => {
    if (!disabled && onClick) {
      onClick();
    }
  }, [disabled, onClick]);

  const computedCn = useMemo(() => cn(disabled ? '' : bgClass, className), [disabled, bgClass]);

  return {
    handleClick,
    className: computedCn,
  };
};
